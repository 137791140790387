import { Component, OnInit } from '@angular/core';
import { ScriptService } from '../services/script.service';
import { AppScript } from '../app.models';

@Component({
  selector: 'app-new-script',
  templateUrl: './new-script.component.html',
  styleUrls: ['./new-script.component.css']
})
export class NewScriptComponent implements OnInit {

  public title: string;
  public description: string;
  public name: string;
  public params: string;

  constructor(
    private scriptSvc: ScriptService
  ) { }

  ngOnInit() {
  }

  async register() {
    const parameters = JSON.parse(this.params);
    console.log(parameters);

    const script: AppScript = {
      title: this.title,
      description: this.description,
      scriptName: this.name,
      params: parameters,
      lastRun: ''
    };

    await this.scriptSvc.upsertAppScript(script);
  }

}
