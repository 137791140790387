import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './mat.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

import { HomeComponent } from './home/home.component';
import { SigninComponent } from './signin/signin.component';
import { environment } from 'src/environments/environment';
import { AdminGuard } from './admin.guard';
import { ScriptService } from './services/script.service';
import { NewScriptComponent } from './new-script/new-script.component';
import { FormsModule } from '@angular/forms';

const routes: Route[] = [
  { path: '', component: HomeComponent }, //, canActivate: [AdminGuard]
  { path: 'signin', component: SigninComponent },
  { path: 'new', component: NewScriptComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SigninComponent,
    NewScriptComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    FormsModule,
    AppMaterialModule,

    // Firebase modules
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
  ],
  providers: [
    ScriptService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
