import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AppScript } from '../app.models';
import { BehaviorSubject } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';

export enum AppEnvironment {
  DEV = 'dev',
  PROD = 'prod'
}

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private scriptRef: AngularFirestoreCollection<AppScript>;
  public scripts = new BehaviorSubject<AppScript[]>([]);

  constructor(
    private db: AngularFirestore,
    private fireFunctions: AngularFireFunctions
  ) {
    this.scriptRef = this.db.collection<AppScript>('/scripts');
    this.scriptRef.valueChanges().subscribe(scripts => this.scripts.next(scripts));
  }

  createId() {
    return this.db.createId();
  }

  getAll() {
    return this.scriptRef.valueChanges();
  }

  async upsertAppScript(script: AppScript) {
    if (!script.id) {
      script.id = this.db.createId();
    }
    await this.scriptRef.doc(script.id).set(script);
  }

  async execute(script: AppScript, environment: string) {
    return await this.call('executeScript', script, environment);
  }

  async undo(script: AppScript, environment: string) {
    return await this.call('undoScript', script, environment);
  }

  private async call(name: string, script: AppScript, environment: string) {
    const callable = this.fireFunctions.httpsCallable(name);

    const promise = callable({
      id: script.id,
      script: script.scriptName,
      params: null,
      environment
    }).toPromise();

    return promise;
  }

}
