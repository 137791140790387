// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBGeSnGzyIorz7sxsNpj74Xm60rybzuq50',
    authDomain: 'callingqueue-admin.firebaseapp.com',
    databaseURL: 'https://callingqueue-admin.firebaseio.com',
    projectId: 'callingqueue-admin',
    storageBucket: 'callingqueue-admin.appspot.com',
    messagingSenderId: '69814759781',
    appId: '1:69814759781:web:f021ebf47eb7a2ce'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
