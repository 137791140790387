import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit() {
  }

  async signin() {
    const googleProvider = new auth.GoogleAuthProvider();
    const credentials = await this.afAuth.auth.signInWithPopup(googleProvider);

    if (credentials != null) {
      this.router.navigateByUrl('');
    }
  }

}
