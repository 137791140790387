import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ScriptService, AppEnvironment } from '../services/script.service';
import { AppScript } from '../app.models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public selectedScript: AppScript;
  public scriptResult: any;
  public env: string;

  constructor(
    public scriptService: ScriptService
  ) { }

  ngOnInit() {
  }

  async execute() {
    console.log(this.selectedScript);
    this.scriptResult = await this.scriptService.execute(this.selectedScript, this.env);
  }

  async undo() {
    console.log(this.selectedScript);
    this.scriptResult = await this.scriptService.undo(this.selectedScript, this.env);
  }
}
